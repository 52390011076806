<script lang="ts">
  import { twMerge } from 'tailwind-merge';

  export let level = 1;
  let clazz: string = '';
  export { clazz as class };

  let levelClasses = ['text-2xl'];
  $: {
    switch (level) {
      case 1:
        levelClasses = ['text-2xl'];
        break;
      case 2:
        levelClasses = ['text-xl'];
        break;
      case 3:
        levelClasses = ['text-2lg'];
        break;
      case 4:
        levelClasses = ['text-lg'];
        break;
      case 5:
        levelClasses = ['text-base'];
        break;
      case 6:
        levelClasses = ['text-base'];
        break;
    }
  }
  $: mergedClass = twMerge('text-slate-600', 'font-medium', ...levelClasses, clazz);
</script>

{#if level === 1}
  <h1 class={mergedClass}><slot /></h1>
{:else if level === 2}
  <h2 class={mergedClass}><slot /></h2>
{:else if level === 3}
  <h3 class={mergedClass}><slot /></h3>
{:else if level === 4}
  <h4 class={mergedClass}><slot /></h4>
{:else if level === 5}
  <h5 class={mergedClass}><slot /></h5>
{:else}
  <h6 class={mergedClass}><slot /></h6>
{/if}
